/* globals ajax */

// import { breakpoints } from '../variables.yml';

// import { whatever } from './vendor/lib.js';
// import { getWindowWidth } from './helpers.js';

import './vendor/ajax.min.js';
import './vendor/intersection-observer.js';
import './vendor/selected-options.js';
import { setupShareLinks } from './setup-share-links.js';
import { Formulator } from './formulator.js';

export { Formulator };
export * from './CookieConsentManager.js';

( () => {
	// Event filter toggle
	window.addEventListener( 'click', ( e ) => {
		if ( !e.target.classList.contains( 'event-filter__toggle' ) ) {
			return;
		}
		const bar = document.querySelector( '.event-filter__controls' );
		if ( !bar ) {
			return;
		}
		bar.classList.toggle( 'event-filter__controls--hidden' );
	} );
} )();

( () => {
	// Event filter submit
	window.addEventListener( 'change', ( e ) => {
		if ( !e.target.classList.contains( 'event-filter__checkbox' ) ) {
			return;
		}
		const form = e.target.form;
		if ( !form ) {
			return;
		}
		form.submit();
	} );
} )();

( () => {
	// ThankQ booking start button
	window.addEventListener( 'change', ( e ) => {
		if ( e.target.classList.contains( 'ticket-select' ) ) {
			const submit = e.target.form.querySelector( 'button[type="submit"]' );
			if ( !submit ) {
				return;
			}
			let total_tickets = 0;
			const dropdowns = document.querySelectorAll( '.ticket-select' );
			for ( let i = 0; i < dropdowns.length; i++ ) {
				total_tickets += parseInt( dropdowns[i].value );
			}
			if ( total_tickets === 0 ) {
				submit.classList.add( 'removed' );
				return;
			}
			submit.classList.remove( 'removed' );
		}
	} );
} )();

( () => {
	// Async feeds
	function handleAjaxSuccess( feed_container ) {
		return function( data ) {
			feed_container.classList.add( 'external-feed--loaded' );
			feed_container.innerHTML = data;
		};
	}

	function handleAjaxError( feed_container ) {
		return function() {
			feed_container.classList.add( 'external-feed--loaded' );
			feed_container.innerHTML = '<p class="p">Unable to load ticket options. Please try again later.</p>';
		};
	}

	const feed_containers = document.querySelectorAll( '.external-feed' );

	if ( !feed_containers ) {
		return;
	}

	for ( let t = 0; t < feed_containers.length; t++ ) {
		const url = feed_containers[t].getAttribute( 'data-feed-url' );
		if ( !url ) {
			return;
		}
		ajax( {
			type: 'get',
			url: url,
			timeout: 8000,
			onSuccess: handleAjaxSuccess( feed_containers[t] ),
			onError: handleAjaxError( feed_containers[t] )
		} );
	}

} )();


( () => {
	// Video embeds

	function buildVideoPlayer( container ) {
		const embed_url = container.getAttribute( 'data-embed-url' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', 1640 );
		iframe.setAttribute( 'height', 923 );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'title', 'Embedded video content' );
		iframe.setAttribute( 'frameborder', 0 );
		iframe.setAttribute( 'allowfullscreen', true );
		container.classList.add( 'flex-container' );
		container.classList.add( 'video-embed--playing' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	function handleVideoClick( container ) {
		return function( event ) {
			event.preventDefault();
			buildVideoPlayer( container );
		};
	}

	const videos = document.querySelectorAll( '.video-embed' );
	for ( let j = 0; j < videos.length; j += 1 ) {
		videos[j].addEventListener( 'click', handleVideoClick( videos[j] ) );
	}

} )();

( () => {
	// Lazy loading
	const lazy_items = document.querySelectorAll( '[data-lazy-iframe], [data-lazy-src]' );
	if ( !lazy_items ) {
		return;
	}

	function restoreItem( element ) {
		if ( element.hasAttribute( 'data-lazy-iframe' ) ) {
			const src = element.getAttribute( 'data-lazy-iframe' );
			const iframe = document.createElement( 'iframe' );
			iframe.src = src;
			element.classList.remove( 'lazy--unloaded' );
			element.appendChild( iframe );
		} else {
			const src = element.getAttribute( 'data-lazy-src' );
			const image = document.createElement( 'img' );
			image.addEventListener( 'load', () => {
				element.classList.remove( 'lazy--unloaded' );
			} );
			image.src = src;
			image.setAttribute( 'alt', element.getAttribute( 'data-lazy-caption' ) );
			element.appendChild( image );
		}
	}

	if ( typeof window.IntersectionObserver === 'undefined' ) {
		for ( let i = 0; i < lazy_items.length; i++ ) {
			restoreItem( lazy_items[i] );
		}
	}

	const observer = new IntersectionObserver( ( entries ) => {
		for ( let i = 0; i < entries.length; i++ ) {
			if ( !entries[i].isIntersecting ) {
				continue;
			}
			restoreItem( entries[i].target );
			observer.unobserve( entries[i].target );
		}
	}, {
		// Adjust the point in relation to the viewport at which the item is loaded.
		rootMargin: '-50px 0px -50px 0px',
	} );

	for ( let i = 0; i < lazy_items.length; i++ ) {
		observer.observe( lazy_items[i] );
	}
} )();

( () => {
	// Toggle handling
	function handleToggle( toggler ) {
		const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
		return () => {
			for ( let i = 0; i < togglees.length; i += 1 ) {
				if ( togglees[i].classList.contains( 'toggled' ) ) {
					togglees[i].classList.remove( 'toggled' );
					continue;
				}
				togglees[i].classList.add( 'toggled' );
			}
			if ( toggler.classList.contains( 'toggler--a' ) ) {
				toggler.classList.remove( 'toggler--a' );
				toggler.classList.add( 'toggler--b' );
				return;
			}
			toggler.classList.remove( 'toggler--b' );
			toggler.classList.add( 'toggler--a' );
		};
	}
	const togglers = document.querySelectorAll( '.toggler' );
	for ( let i = 0; i < togglers.length; i += 1 ) {
		togglers[i].addEventListener( 'click', handleToggle( togglers[i] ) );
	}
} )();

( () => {
	const nav_toggle = document.getElementById( 'js-nav-toggle' );
	const nav_items = document.querySelectorAll( '.header__item' );

	nav_toggle.addEventListener( 'click', () => {
		document.body.classList.toggle( 'mobile-nav-open' );
		( function revealNavItem( i = 0 ) {
			setTimeout( () => {
				nav_items[i].classList.add( 'visible' );
				i++;
				if ( i < nav_items.length ) {
					revealNavItem( i );
				}
			}, 100 );
		} )( 0 );
	} );
} )();

( () => {
	// Hide groups
	// Used for showing/hiding form fields depending on a checkbox. All children
	// with the same key as the parent will get display:none when the parent is
	// checked.
	// All other parents with the same group will get disabled when the parent
	// is checked.
	function inputIsChecked( element ) {
		if ( element instanceof HTMLSelectElement ) {
			return element.selectedOptions[0].hasAttribute( 'data-checked' );
		}
		return element.checked;
	}
	function updateHiddenThings( event ) {
		const hide_status = {};
		const hide_parents = document.querySelectorAll( '[data-hide-key]' );
		const hide_children = document.querySelectorAll( '[data-hide-parent]' );
		for ( let i = 0; i < hide_parents.length; i++ ) {
			const key = hide_parents[i].getAttribute( 'data-hide-key' );
			if ( !Object.prototype.hasOwnProperty.call( hide_status, key ) ) {
				hide_status[key] = false;
			}
			hide_status[key] = hide_status[key] || inputIsChecked( hide_parents[i] );
			const group = hide_parents[i].getAttribute( 'data-hide-group' );
			if ( group === null ) {
				continue;
			}
			if ( event && group !== event.target.getAttribute( 'data-hide-group' ) ) {
				continue;
			}
			if ( event && hide_parents[i] === event.target ) {
				continue;
			}
			if ( event && inputIsChecked( event.target ) ) {
				hide_parents[i].disabled = true;
				continue;
			}
			hide_parents[i].disabled = false;
		}
		for ( let j = 0; j < hide_children.length; j++ ) {
			const keys = hide_children[j].getAttribute( 'data-hide-parent' ).split( '|' );
			const disable = hide_children[j].hasAttribute( 'data-hide-disable' );
			const opposite = hide_children[j].hasAttribute( 'data-hide-opposite' );
			const autoselect_radio = hide_children[j].hasAttribute( 'data-hide-autoselect-radio' );
			let child_hide_status = false;
			for ( let k = 0; k < keys.length; k++ ) {
				if ( !Object.prototype.hasOwnProperty.call( hide_status, keys[k] ) ) {
					continue;
				}
				child_hide_status = child_hide_status || hide_status[keys[k]];
			}
			if ( disable ) {
				hide_children[j].disabled = child_hide_status;
				continue;
			}
			if ( autoselect_radio ) {
				let first_radio = hide_children[j].querySelector( '[data-autoselect]' );
				if ( !first_radio ) {
					first_radio = hide_children[j].querySelector( 'input[type=radio]' );
				}
				if ( child_hide_status && event && event.target.name !== first_radio.name ) {
					const parents = [];
					for ( let l = 0; l < keys.length; l++ ) {
						const parent = document.querySelector( '[data-hide-key=' + keys[l] + ']' );
						if ( !parent ) {
							continue;
						}
						parents.push( parent );
					}
					if ( parents.indexOf( event.target ) > -1 ) {
						first_radio.checked = true;
						window.setTimeout( () => {
							let e;
							if ( typeof( Event ) === 'function' ) {
								e = new Event( 'change', { bubbles: true } );
							} else { // IE11
								e = document.createEvent( 'Event' );
								e.initEvent( 'submit', true, false );
							}
							first_radio.dispatchEvent( e );
						}, 1 );
					}
				}
			}
			if ( opposite ) {
				hide_children[j].style.display = ( child_hide_status ? '' : 'none' );
				continue;
			}
			hide_children[j].style.display = ( child_hide_status ? 'none' : '' );
		}
	}
	window.addEventListener( 'change', updateHiddenThings );
	updateHiddenThings();
} )();

( () => {
	// Dynamic amount fields
	const populators = document.querySelectorAll( '[data-populate-target]' );

	if ( populators.length === 0 ) {
		return;
	}

	function normaliseAmount( amount ) {
		amount = parseFloat( amount );
		if ( isNaN( amount ) || amount < 0 ) {
			return 0;
		}
		return amount;
	}

	function highlightPopulators() {
		for ( let i = 0; i < populators.length; i++ ) {
			const current_amount = normaliseAmount(
				document.getElementById(
					populators[i].getAttribute( 'data-populate-target' )
				).value
			);
			const value = normaliseAmount(
				populators[i].getAttribute( 'data-populate-amount' )
			);
			if ( value === current_amount ) {
				populators[i].classList.add(
					populators[i].getAttribute( 'data-populate-active-class' )
				);
				continue;
			}
			populators[i].classList.remove(
				populators[i].getAttribute( 'data-populate-active-class' )
			);
		}
		const event = document.createEvent( 'Event' );
		event.initEvent( 'populators_populate', true, true );
		window.dispatchEvent( event );
	}

	const targets = [];
	for ( let i = 0; i < populators.length; i++ ) {
		const target = document.getElementById(
			populators[i].getAttribute( 'data-populate-target' )
		);
		if ( targets.indexOf( target ) !== -1 ) {
			continue;
		}
		targets.push( target );
	}

	for ( let i = 0; i < targets.length; i++ ) {
		targets[i].addEventListener( 'change', () => {
			highlightPopulators();
		} );
	}

	function handlePopulatorClick( populator ) {
		const value = normaliseAmount(
			populator.getAttribute( 'data-populate-amount' )
		).toFixed( 2 );
		return () => {
			document.getElementById(
				populator.getAttribute( 'data-populate-target' )
			).value = value;
			highlightPopulators();
		};
	}

	for ( let i = 0; i < populators.length; i++ ) {
		populators[i].addEventListener(
			'click',
			handlePopulatorClick( populators[i] )
		);
	}

	highlightPopulators();
} )();

( () => {
	// Stop double form submission
	const forms = document.querySelectorAll( 'form' );
	for ( let y = 0; y < forms.length; y++ ) {
		forms[y].addEventListener( 'submit', ( e ) => {
			return preventDoubleSubmission( forms[y], e );
		} );
	}
	function preventDoubleSubmission( form, e ) {
		if ( form.hasAttribute( 'data-submitting' ) ) {
			e.preventDefault();
			return;
		}
		form.setAttribute( 'data-submitting', true );
	}
} )();

( () => {
	// Set up share links
	setupShareLinks( '.social-share__link' );
} )();

( () => {
	// Print buttons
	const print_buttons = document.querySelectorAll( '.js-print' );
	for ( let i = 0; i < print_buttons.length; i++ ) {
		print_buttons[i].addEventListener( 'click', () => {
			window.print();
		} );
	}
} )();

( () => {
	// Field character count
	window.addEventListener( 'keyup', ( event ) => {
		if ( !( 'charLimit' in event.target.dataset ) ) {
			return;
		}
		if ( event.target.dataset.charLimit === '' ) {
			return;
		}
		countCharacters( event.target );
	} );

	function countCharacters( element ) {
		const limit = parseInt( event.target.dataset.charLimit, 10 );
		if ( isNaN( limit ) || limit < 1 ) {
			return;
		}
		let notice = element.parentNode.querySelector( '.form-field__character-count' );
		if ( !notice ) {
			notice = document.createElement( 'div' );
			notice.className = 'form-field__character-count';
			element.parentNode.insertBefore( notice, element );
		}
		const remaining_chars = limit - element.value.length;
		notice.innerHTML = remaining_chars === limit ? '' : remaining_chars;
		notice.classList.toggle(
			'form-field__character-count--invalid',
			remaining_chars < 1
		);
	}
} )();
